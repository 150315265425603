import React from 'react'
import { Link } from 'react-router-dom'
import HomeSlider from'../Component/HomeSlider'
import HomeGallery from'../Component/HomeGallery'
import Calender from'../Component/Calender'
import HomeNews from'../Component/HomeNews'
import Header from'../Component/Header'
import Footer from '../Component/Footer'
const Home = () => {
  return (
    <>
    <div className="erp_links">
    <Link to="https://dppsk.campuscare.cloud/" target="_blank"><img src="/Images/Campulogo.png" alt="Delhi Police Public School" className="img-fluid" /></Link> 
   </div>
   {/* <div className="OnlineR">
   <span className="bounce1">
    <Link to="https://dppsk.campuscare.cloud/" target="_blank" className="parantlogo"> Online Registration </Link>
    <Link to="#" target="_blank" className=""> <i className="bi bi-paperclip"></i></Link>
   </span>
   </div> */}
   <div className="highlights-sec">
   <marquee 
   onMouseOver={(event) => event.currentTarget.stop()}
   onMouseOut={(event) => event.currentTarget.start()}
 >
   <p> <Link to="#" target="_blank">Stay Tunned for More Updates <i className="fa fa-paperclip" aria-hidden="true"></i>
   </Link> &nbsp;|</p>
   <p>&nbsp;  <Link to="#" target="_blank">Stay Tunned for More Updates  <i className="fa fa-paperclip" aria-hidden="true"></i></Link> &nbsp;|</p>
   <p>&nbsp;  <Link to="#" target="_blank">Stay Tunned for More Updates </Link> </p>
   
   </marquee>
   </div>
  
        <Header/>
    <HomeSlider/>

    <div className="about-sec">
    <div className="container">
    <div className="row">
    <div className="col-xxl-7 col-xl-7 col-lg-12">
    <article className="about-white">
    <h1>Welcome to DPPS / Our School</h1>
    <p>DPPS aims at providing comprehensive education, nourishing our rich culture, heritage and tradition while maintaining a progressive attitude towards the future needs, fostering academic rigour with a caring heart and appreciating individuals.  Today, in a world where society is fragmented and polarized, we strive to produce individuals who appreciate honesty, integrity and hard work.  We at DPPS value holistic education by providing them the much-needed infrastructure with planned activities and methodical teaching approach.</p>
    <div className="read-more"> <Link to="#"><span>Read More</span> <i className="fa-solid fa-arrow-right"></i></Link></div>
    </article>	
    </div>
    <div className="col-xxl-5 col-xl-5 col-lg-12">
    <article className="about-clr">
    <img src="/Images/about.png" className="cubeHide img-fluid" alt="Delhi Police Public School Kingsway,Camp"/>
    </article>	
    </div>	
    </div>
    </div>
    </div>
    
    <div className="main-sec">
    <div className="container">
    <div className="row">
    <div className="col-xxl-6 col-xl-6 col-lg-12 news-sec">
    <h1>Importance Notice</h1>
    <div className="accordion" id="accordionExample">
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
          DPPS News Title - 1
        </button>
      </h2>
      <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div className="accordion-body">
           <p>Stay tuned for latest updates.. Stay tuned for latest updates</p>
           <Link to="#" target="_blank">View</Link>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwo">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
         DPPS News Title - 2
        </button>
      </h2>
      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
        <div className="accordion-body">
          <p>Stay tuned for latest updates.. Stay tuned for latest updates</p>
           <Link to="#" target="_blank">View</Link>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
         DPPS News Title - 3
        </button>
      </h2>
      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
        <div className="accordion-body">
          <p>Stay tuned for latest updates.. Stay tuned for latest updates</p>
           <Link to="#" target="_blank">View</Link>
        </div>
      </div>
    </div>
  </div>
         <Link to="#" className="view"><span>View More</span> <i className="fa-solid fa-arrow-right"></i></Link>
    </div>
    <div className="col-xxl-6 col-xl-6 col-lg-12 msg-sec">
    <article>
    <div className="data-msg">
    <div className="img-sec"><img src="/Images/principal.jpg" alt="Delhi Police Public School Kingsway,Camp"/></div>
    <h3>HOS Message</h3>	
    </div>		
    <p>Delhi Police Public School, New Police Lines, Delhi has completed twelve successful years this year. The last twelve years have undoubtedly been times that have been extremely engaging, fulfilling and rich in diversity and experience.
    <Link to="/">View More <span><i className="fa-solid fa-arrow-right"></i></span></Link></p>	
    <h4>With warm regards, <br/> Ms. Tarunima Roy</h4>	
    </article>
    </div>	
    </div>
    </div>	
    </div>	
    <div className="vision-sec">
    <div className="container">
    <article>
    <h1>Delhi Police Public School Kingsway,Camp</h1>
    <p> To develop scholastic personalities and impart knowledge to maximize skills and competencies. To foster a positive school atmosphere of sharing and caring to help them achieve scholastically and co-scholastically their greatest potential. </p>	
    </article>	
    </div>
    </div>
    <div className="wave-beyond"></div>
    <div className="beyond-sec">
    <div className="container">
    <h1>Beyond Academics</h1>	
    <div className="imp-links">
    <div className="links"><article><h3> <Link to="#"><img src="/Images/toppers.png" alt="Delhi Police Public School Kingsway,Camp"/>  Achievers</Link></h3></article></div>
    <div className="links"><article><h3> <Link to="#"><img src="/Images/alumni.png" alt="Delhi Police Public School Kingsway,Camp"/>  Alumni</Link></h3></article></div>	
    <div className="links"><article><h3> <Link to="#"><img src="/Images/tc.png" alt="Delhi Police Public School Kingsway,Camp"/>  Transfer Certificate</Link></h3></article></div>
    <div className="links"><article><h3> <Link to="#"><img src="/Images/task.png" alt="Delhi Police Public School Kingsway,Camp"/>  Workshop</Link></h3></article></div>
    <div className="links"><article><h3> <Link to="#"><img src="/Images/publications.png" alt="Delhi Police Public School Kingsway,Camp"/>  Publications</Link></h3></article></div>	
    </div>	
    </div>	
    </div>


    
    <div className="gallery-sec">
    <h1>Memories Makes Us Happy<span> <Link to="#"><i className="fa fa-arrow-right"></i></Link></span></h1>	
    <div className="container">
    <div className="row">
          <div className="col-md-12">
              <HomeGallery/> 
          </div>
        </div>
    </div>	
    </div>
    <div className="wave-testi"></div>
    <div className="testimonials-sec">
<div className="container">
<div className="row">
<div className="col-xxl-7 col-xl-7 col-lg-12">
<h1>News </h1>	
<div className="row">
   <div className="col-lg-12">
       <HomeNews/>
   </div>
</div>		
</div>
<div className="col-xxl-5 col-xl-5 col-lg-12">
<h1>Academic Calendar <span><Link to="/pdf/Annual-Calender.pdf" target="_blank"><i className="bi bi-arrow-bar-right"></i></Link></span></h1>
     <Calender/>
</div>	
</div>	
</div>	
</div>	
  <Footer/>
    </>
       
  )
}

export default Home
